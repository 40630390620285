import React, { PureComponent } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import Link from '../../SEO/Link';
import { normalizeString } from '@dmm/lib-common/lib/formatting';

import {
  isFacetChild,
  isFacetParent
} from '../../../utils/twoLevelGroupFacets';
import {nil} from '../../../utils/runOnceHelper';
import {asArray} from '../../../utils/commonHelper';

const hullTypeNameMap = {
  'Ferro-cement': 'Ferro Cement',
  'Fiberglass/Composite': 'Fiberglass Composite',
  'Fiberglass/Reinforced': 'Fiberglass Reinforced'
};

const getHullClass = facet => {
  return isFacetChild(facet) ? 'facet-child' :
    isFacetParent(facet) ? 'facet-parent' : '';
};

export default class FilterHullType extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedHullTypes: []
    };
    this.getFiberglassGroup = this.props.getFiberglassGroup || nil;
  }

  updateMultiInput() {
    if (this.props.selectedHullTypes !== undefined) {
      this.setState({ selectedHullTypes: this.props.selectedHullTypes });
      const group = this.getFiberglassGroup(this.props?.hullTypes);
      group?.selectFromTypes(this.props.selectedHullTypes);
    }
  }

  handleToggleHullType = (hullType) => {
    let { selectedHullTypes } = this.state;
    let newHullTypes;
    const fiberGlassGroup = this.getFiberglassGroup(this.props?.hullTypes);
    const fiberGlassItem = fiberGlassGroup?.itemByNormalizedId(hullType);
    if (includes(selectedHullTypes, hullType)) {
      if (this.props.tracking) {
        this.props.tracking.facetRemoved(`hull type removed - ${hullType}`);
      }
      fiberGlassItem?.deselect();
      newHullTypes = filter(selectedHullTypes, item => item !== hullType);
    }
    else {
      if (this.props.tracking) {
        this.props.tracking.facetAdded({ 'hullMaterial': [hullType] });
      }
      fiberGlassItem?.select();
      newHullTypes = [...asArray(selectedHullTypes), hullType];
    }
    const newTypes = fiberGlassGroup?.addFiberGlassToHullTypes(newHullTypes) || newHullTypes;
    this.setState({
      selectedHullTypes: newTypes
    });
    this.props.handleDataChange('hullMaterial', newTypes);
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  onClickModelLink = (e) => {
    e.preventDefault();
  }

  render() {
    let { hullTypes, params } = this.props;
    const { selectedHullTypes } = this.state;

    return <div className="search-filter">
      <div className="search-filter-group">
        <ToolSetOptions>
          { hullTypes
            .map(hullType => {
              const name = hullTypeNameMap[hullType.name] ? hullTypeNameMap[hullType.name] : hullType.name;
              return <ToolSetOptionsItem
                optionClass={getHullClass(hullType)}
                type="checkbox"
                key={`HullType-${normalizeString(hullType.urlId)}`}
                id={`HullType-${normalizeString(hullType.urlId)}`}
                name="HullType"
                value={normalizeString(hullType.urlId)}
                selected={includes(selectedHullTypes, normalizeString(hullType.urlId))}
                onClick={this.handleToggleHullType}>
                <Link className="model-link" href={generateSearchPath({hullMaterial: normalizeString(hullType.urlId)}, params, true)} onClick={this.onClickModelLink}>{name}</Link>
              </ToolSetOptionsItem>;
            })
          }
        </ToolSetOptions>
      </div>
    </div>;
  }
}
